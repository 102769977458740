<template>
  <div class="special">
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">{{ title }}</span>
        <span class="banner-fMin banner-other"
          >送政策、送服务，助推企业发展</span
        >
      </div>
    </div>
    <div class="special-container width-limit" ref="declareContainer">
      <div class="base-contianer" >
        <div class="b-title el-row--flex flex-row-between">
			扶持政策
			<div  class="el-row--flex">
				<el-input placeholder="请输入关键字" clearable v-model="keyword">
				</el-input>
				<div class="search-button" @click="searchByKeyword"><i class="el-icon-search" color="#fff"></i></div>
			</div>
		</div>
        <el-table
          :data="fczcList"
          style="width: 100%"
		  class="pointer"
          @row-click="tableRowClick"
        >
          <el-table-column prop="name" label="标题" width="1000">
          </el-table-column>
          <el-table-column prop="policypubdate" label="发布时间" align="right" width="180">
          </el-table-column>
        </el-table>
		<div class="page-able">
			<el-pagination hide-on-single-page style="margin-top: 20px" layout="total, prev, pager, next" background :total="totalSize"
			 :page-size="pageSize" @current-change="changePageNum"></el-pagination>
		</div>

      </div>
      <div class="base-contianer">
        <div class="b-title">专项</div>
        <div class="b-content">
          <div class="item" v-for="(item, i) in zxList" :key="i">
            <DeclareCell
              v-if="i < 9"
              :item="item"
              @clickCell="clickCell"
				:key="item.id"
				:collectItem="isItemCollect(item)"
				:isShowText="false"
				 @clickIsFollow="clickIsFollow"
            ></DeclareCell>
          </div>
        </div>
      </div>
      <div class="bsfw-title">办事服务</div>
      <div class="base-contianer">
        <div class="b-title">复工复产</div>
        <div class="b-content">
          <div class="card-to" v-for="(item, i) in fgfcList" :key="i">
            <div>
              <p class="card-title">{{ item.title }}</p>
              <p class="card-content">{{ item.content }}</p>
            </div>
            <el-popover
              placement="right"
              width="100"
              trigger="hover"
            >
              <el-image  class="hover-img" :src="item.src"></el-image>
              <el-button slot="reference" class="blue-item">{{ item.ts }} →</el-button>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="base-contianer">
        <div class="b-title">企业扶持</div>
        <div class="b-content">
          <div class="card-to" v-for="(item, i) in qyfcList" :key="i">
            <div>
              <p class="card-title">{{ item.title }}</p>
              <p class="card-content">{{ item.content }}</p>
            </div>
            <div class="card-bottom" @click="qyfcClick(item)">
              {{ item.ts }} →
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-img" @click="gotoHandle">
        <div class="left">
          <p class="title">企业热门事项办理专区</p>
          <p class="content">便利疫情防控期间，全区企业和群众办理政务事项</p>
        </div>
        <div class="right">前往办理 →</div>
      </div>
	  
	  <!--登录提示弹窗-->
	  <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
	    <span>需要登录才能收藏，是否登录？</span>
	    <span slot="footer" class="dialog-footer">
	      <el-button @click="dialogVisible = false">取 消</el-button>
	      <el-button type="primary" @click="gotoLogin">确 定</el-button>
	    </span>
	  </el-dialog>
    </div>
  </div>
</template>

<script>
import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片

export default {
  components: {
    DeclareCell,
  },
  computed: {},
  data() {
    return {
		// 关键字
		keyword:"",
      title: "",
	  totalSize:0,//总量
	  pageSize:5,//每一页大小
	  currentNum:1,//当前页
      /* 扶持政策 */
      fczcList: [
      
      ],
      /* 专项申报 */
      zxList: [],
      /* 复工复产 */
      fgfcList: [
        {
          title: "粤省事健康申报",
          content:
            "企事单位员工在线申报健康状况，统计健康数据，掌握全员疫情动态",
          ts: "健康申报",
          ref: "popover1",
          src: require("@/assets/images/qrcode_yueshengshi.png"),
        },
        {
          title: "防疫物质供需平台",
          content: "防疫物资需求信息，防疫物资供应信息",
          ts: "立即查看",
          ref: "popover2",
          src: require("@/assets/images/qrcode_wuzi.png"),
        },
        //     {
        //       title: "企业口罩供需对接",
        //       content: "口罩供应相关服务",
        //       ts: "企业复工口罩申购",
        // ref:'popover3',
        // src: require('@/assets/404_images/no_data.png')
        //     },
      ],
	  //收藏列表 
	  collectList:[],
      /* 企业扶持 */
      qyfcList: [
        {
          title: "企业诉求平台",
          content:
            "企业诉求响应用工、融资、税务、出口等诉求在线反馈，政府协调处理",
          path: "http://www.gdzwfw.gov.cn/portal/qyzx/#/",
          ts: "填写",
        },
        {
          title: "人民银行的“粤信融”平台",
          content: "整合信贷产品资源，为企业提供一站式的融资贷款服务",
          path: "https://static-yst.gdzwfw.gov.cn/yxr_zd/#pages/home/index",
          ts: "办理",
        },
        {
          title: "企业招聘",
          content: "南方网招聘会、地市招聘服务",
          path:
            "https://static-yst.gdzwfw.gov.cn/default/#/pages/recruit/recruit-information/index",
          ts: "填写",
        },
        {
          title: "企业招聘",
          content: "南方网招聘会、地市招聘服务",
          path: "http://apps.gdzwfw.gov.cn/yqfk/business-matters.html",
          ts: "填写",
        },
      ],
	  //弹窗 
	  dialogVisible:false
    };
  },
  created(){
	
	},
  mounted() {
    let item = JSON.parse(sessionStorage.getItem("specialColumn"));
    console.log("item", item);
    if (item && item.title) {
      this.title = item.title;
    }
	this.getPublicList();
    this.loadData();
	this.getCollectList()
  },
  methods: {
		//查询所有的收藏列表,之后需要比对事项的收藏状态
		getCollectList() {
		  if (this.$store.getters.getUserInfo) {
		    let identify = this.$store.getters.getUserInfo.shxydm;
		    let param = {
		      identify: identify,
		      type: "1,2,3,4,5,6",
		      pageNum: 1,
		      pageSize: 100,
		    };
		    let url = "/dev-api/collection/list";
		
		    this.$httpApi
		      .get(url, param)
		      .then((res) => {
		        if (res.code == 200) {
		          this.collectList = res.rows;
		        }
		      })
		      .catch((err) => {});
		  }
		},
		//比对收藏列表,判断是否收藏
		isItemCollect(item) {
		  let list = this.collectList;
		
		  for (let i = 0; i < list.length; i++) {
		    let collectItem = list[i];
		    if (item.id == collectItem.collectionId) {
		      console.log(item.name + ":已收藏");
		      return collectItem;
		    }
		  }
		  return null;
		},
		// 判断是否登录
		clickIsFollow(item) {
		  //判断是否登录
		  if (this.$store.getters.getUserInfo) {
		    //登录
		  } else {
		    //未登录
		    this.dialogVisible = true;
		  }
		},
    loadData() {
      let param = {
        pageNum: 1,
        pageSize: 20,
      };
      let url = "/dev-api/basicinfo/baseitem/xmsbList";
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        this.$httpApi
          .get(url, param)
          .then((res) => {
            console.log("项目列表:", res);
            if (res.code == 200) {
              this.zxList = res.rows;
            }
          })
          .catch((err) => {});
      });
    },
	/* 
	 *此处为获得扶持政策列表的地方
	 */
	getPublicList() {
	  // type 1项目政策 2通知公告 3公开公示
	  let params = {
	    pageNum: this.currentNum,
	    pageSize: this.pageSize,
	    type: 1,
		name:this.keyword,
	  };
	console.log(params)
	  this.$httpApi
	    .get(`/dev-api/document/list`, params)
	    .then((res) => {
	      console.log("项目政策列表:", res);
	      if (res.code === 200) {
	        this.fczcList = res.rows;
	        if (this.fczcList != null) {
	          this.totalSize = res.total;
	        }
	      }
	    })
	    .catch((err) => {
	      this.$message({
	        message: "网络异常，请稍后重试",
	        type: "error",
	      });
	    });
	},
    /**
     * 专项 卡片点击事件
     * @param {object} item 卡片信息
     * @return 转跳到专项详细
     */
    clickCell(item) {
  
      this.$store.commit("updateCurrentGuideId", item.id);
      if (["1", "2"].indexOf(item.itemtype) > -1) {
        this.$router.push({
          path: "/guideShenpi",
          query: {
            name: item.name,
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
      // 修改 header nav 状态
      this.$store.commit("updateHNavAction", "/projectList");
    },
    qyfcClick(e) {
      let href = e.path;
      window.open(href, "_blank");
    },
    gotoHandle() {
      let href = "http://apps.gdzwfw.gov.cn/yqfk/business-matters.html ";
      window.open(href, "_blank");
    },
    tableRowClick(val) {
      // this.$message({
      //   message: "功能开发中, 敬请期待",
      //   type: "warning",
      // });
	  let { href } = this.$router.resolve({
	    path: "/notice/detail/" + val.id + "?type=" + this.itemType,
	  });
	  window.open(href, "_blank");
    },
	/* 根据关键字去搜索政策 */
	searchByKeyword(){
		this.getPublicList();
	},
	// 每一行点击事假
	todo(val) {
	 
	},
	// 下一页
	changePageNum(current) {
		//current是当前页数
		this.currentNum = current;
		this.getPublicList();
	},
	/**
	 * 登录提示框点击确定登录，跳转登录页面
	 */
	gotoLogin() {
	  console.log("跳转登录页面");
	  this.$router.push({
	    path: "/login",
	  });
	},
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/index.scss";

/* 头部 */
.special {
  // background: #fff;
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }
  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;
    .banner-other {
      margin-left: 15px;
    }
  }
}

/* 主体部分 */
.special-container {
  background-color: #ffffff;
  .d-title {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    padding-bottom: 30px;
    margin-right: 30px;
  }
  .d-btns {
    padding: 40px 0;
  }
}

.base-contianer {
  width: 100%;
  min-height: 200px;

  .b-title {
    padding: 10px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 4px solid #000;
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  .b-content {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .item {
      width: 32%;
      box-sizing: border-box;
      margin-bottom: 16px;
      margin-right: 16px;
    }
    .card-to {
      width: 31%;
      height: 148px;
      box-sizing: border-box;
      margin-right: 10px;
      margin-bottom: 10px;
      // border: 1px solid #333;
      box-shadow: 0 0 10px 1px rgba(#333, 0.1);
      padding: 14px;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      .card-title {
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }
      .card-content {
        font-size: 14px;
        color: #000;
      }
      .card-bottom {
        font-size: 14px;
        color: #559df5;
        cursor: pointer;
      }
    }
  }
  /* 标题 */
  .el-table__header-wrapper {
  }
  /* 内容 */
  .el-table__body-wrapper {
    /* 状态栏 */
    // .el-table_1_column_1 {
    //     color: $color-danger !important;
    // }
    /* 横向属性 */
    .el-table__row {
      height: 60px;
      cursor: pointer;
      &:hover {
        // height: 70px;
        // font-size: $font-size18;
        font-weight: bold;
        .el-table_1_column_2 {
          color: $color-primary !important;
        }
      }
    }
  }
}
/* 办事服务 */
.bsfw-title {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #000;
  margin: 40px 0;
}
/* 最下方图片 */
.bottom-img {
  width: 100%;
  height: 200px;
  margin: 40px 0 0 0;
  padding: 20px 80px;
  background: #213e5f;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  .left {
    width: 400px;
    .title {
      font-size: 31px;
    }
    .content {
      font-size: 14px;
    }
  }
  .right {
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 6px 30px;
  }
}
.hover-img {
  width: 100%;
  height: 100%;
}
.blue-item {
  color: #559df5;
}
.search-button {
		width: 40px;
		height: 40px;
		border-radius: 0px;
		border: 0px;
		background-color: rgb(69, 141, 252);
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
}
.page-able {
	display: flex;
	justify-content: flex-end;
}
</style>
